import React, { useState, ChangeEvent, FormEvent } from "react";
import strings from "../../constants/strings";
import logo from "../../assets/images/logo.png";

interface FormData {
  name: string;
  email: string;
  title: string;
  comment: string;
}

const ContactUsScreen = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    title: "",
    comment: ""
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});

  const [generalError, setGeneralError] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
    setErrors({ ...errors, [e.target.id]: "" });
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<FormData> = {};

    if (!formData.name.trim()) {
      newErrors.name = "Enter your name.";
    }

    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address.";
    }

    if (!formData.title.trim()) {
      newErrors.title = "Add a brief description.";
    }

    if (!formData.comment.trim()) {
      newErrors.comment = "Share your questions or feedback.";
    }

    setErrors(newErrors);

    const errorCount = Object.keys(newErrors).length;
    if (errorCount > 0) {
      setGeneralError(`Fix all errors before proceeding. Number of errors: ${errorCount}`);
      return false;
    }

    setGeneralError("");
    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await fetch("https://dev-admin.ptlytics.ca/api/website-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer "
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        console.error("Submission failed");
      } else {
        console.log("Email sent successfully");
        setFormData({
          name: "",
          email: "",
          title: "",
          comment: ""
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="min-h-screen w-full bg-home-background bg-cover bg-center grid grid-cols-1 lg:grid-cols-2 lg:gap-x-6">
      <div className="flex flex-col items-center lg:items-start justify-center text-white pt-10 px-10 lg:pl-48 lg:pr-20">
        <img src={logo} alt="PTLytics Logo" className="w-[193px] h-[60px] mb-4" />
        <p className="text-[22px] lg:text-[64px] text-center lg:text-start font-urbanist font-bold">
          {strings.bannerContactUs}
        </p>
      </div>
      <div className="flex items-center justify-center lg:justify-start p-6">
        <div className="bg-white rounded-md shadow-md p-8 w-full max-w-[488px] h-auto">
          <h2 className="text-[24px] lg:text-[28px] font-semibold mb-2 font-urbanist">
            {strings.contactUs}
          </h2>
          <p className="text-custom-gray mb-6 font-normal">
            {strings.contactUsDescription}
          </p>

          {generalError && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-4 rounded">
              <strong>{generalError}</strong>
            </div>
          )}

          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="border border-gray-300 rounded-md p-3">
              <label htmlFor="name" className="block text-custom-gray font-normal text-sm mb-1">
                {strings.nameMandatory}
              </label>
              <input
                id="name"
                type="text"
                placeholder={strings.namePlaceholder}
                className="w-full border-none outline-none placeholder:text-custom-gray text-base"
                value={formData.name}
                onChange={handleChange}
              />

            </div>
            {errors.name && (
              <p className="text-red-600 text-sm mt-1">{errors.name}</p>
            )}

            <div className="border border-gray-300 rounded-md p-3">
              <label htmlFor="email" className="block text-custom-gray font-normal text-sm mb-1">
                {strings.emailMandatory}
              </label>
              <input
                id="email"
                type="email"
                placeholder={strings.emailPlaceholder}
                className="w-full border-none outline-none placeholder:text-custom-gray text-base"
                value={formData.email}
                onChange={handleChange}
              />

            </div>
            {errors.email && (
              <p className="text-red-600 text-sm mt-1">{errors.email}</p>
            )}

            <div className="border border-gray-300 rounded-md p-3">
              <label htmlFor="title" className="block text-custom-gray font-normal text-sm mb-1">
                {strings.titleMandatory}
              </label>
              <input
                id="title"
                type="text"
                placeholder={strings.addDescription}
                className="w-full border-none outline-none placeholder:text-custom-gray text-base"
                value={formData.title}
                onChange={handleChange}
              />

            </div>
            {errors.title && (
              <p className="text-red-600 text-sm mt-1">{errors.title}</p>
            )}

            <div className="border border-gray-300 rounded-md p-3">
              <label htmlFor="comment" className="block text-custom-gray font-normal text-sm mb-1">
                {strings.commentMandatory}
              </label>
              <textarea
                id="comment"
                placeholder={strings.shareQuestion}
                className="w-full border-none outline-none placeholder:text-custom-gray text-base"
                rows={4}
                value={formData.comment}
                onChange={handleChange}
              />

            </div>
            {errors.comment && (
              <p className="text-red-600 text-sm mt-1">{errors.comment}</p>
            )}

            <button
              type="submit"
              className="bg-custom-blue text-white px-4 py-2 rounded-full w-full font-bold"
            >
              {strings.submitBtn}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
