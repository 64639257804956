import React, { useEffect } from "react";
import strings from "../../constants/strings";

const RedirectScreen = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsString = searchParams.toString();
    const redirectUrl = `ptlytics://open-app${paramsString ? "?" + paramsString : ""}`;
    window.location.href = redirectUrl;    
  }, []);

  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center bg-home-background bg-cover bg-center font-urbanist">
      <p className="text-white text-3xl font-bold">
        {strings.redirecting}
      </p>
    </div>
  );
};

export default RedirectScreen;