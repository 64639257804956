import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/home/HomeScreen";
import UploadFileScreen from "./screens/uploadFile/UploadFileScreen";
import UploadFileEditedScreen from "./screens/uploadFileEdited/UploadFileEditedScreen";
import RedirectScreen from "./screens/redirect/RedirectScreen";
import ContactUsScreen from "./screens/contactUs/contactUsScreen";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="home" element={<HomeScreen />} />
        <Route path="upload" element={<UploadFileScreen />} />
        <Route path="upload-edited" element={<UploadFileEditedScreen />} />
        <Route path="redirect-app" element={<RedirectScreen />} />
        <Route path="contact-us" element={<ContactUsScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
