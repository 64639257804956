const strings = {

    //Home
    healthlyticsCanada: "Healthlytics Canada Inc.",
    comingSoon: "Coming soon.",
    redirecting: "Redirecting...",

    //Contact Us
    bannerContactUs: "The smart, patient management system.",
    contactUs: "Contact us",
    contactUsDescription: "Have questions? Fill out the form below to contact tech support.",

    //Form
    nameMandatory: "Name*",
    namePlaceholder: "Jane Doe",
    emailMandatory: "Email*",
    emailPlaceholder: "your@gmail.com",
    titleMandatory: "Title*",
    addDescription: "Add a brief description.",
    commentMandatory: "Comment*",
    shareQuestion: "Share your questions or feedback.",

    submitBtn: "Submit",
}

export default strings;